export default {
  'Check interval': 'Intervallo di controllo',
  Schedule: 'Programma',
  backup: 'Backup',
  downloadTheProjectBackupFile: 'Scarica il file di backup del progetto (in json)',
  restoreProject: 'Ripristina progetto...',
  incorrectUsrPwd: 'Login o password errati',
  askDeleteUser: 'Vuoi davvero eliminare questo utente?',
  askDeleteTemp: 'Vuoi davvero eliminare questo modello?',
  askDeleteEnv: 'Vuoi davvero eliminare questo ambiente?',
  askDeleteInv: 'Vuoi davvero eliminare questo inventario?',
  askDeleteKey: 'Vuoi davvero eliminare questa chiave?',
  askDeleteRepo: 'Vuoi davvero eliminare questo repository?',
  askDeleteProj: 'Vuoi davvero eliminare questo progetto?',
  askDeleteTMem: 'Vuoi davvero eliminare questo membro del team?',
  edit: 'Modifica',
  nnew: 'Nuovo',
  keyFormSshKey: 'Chiave SSH',
  keyFormLoginPassword: 'Accesso con password',
  keyFormNone: 'Nessuno',
  incorrectUrl: 'URL errato',
  username: 'Nome utente',
  username_required: 'Il nome utente è obbligatorio',
  dashboard: 'Cruscotto',
  history: 'Storia',
  activity: 'Attività',
  settings: 'Impostazioni',
  signIn: 'Accedi',
  password: 'Password',
  changePassword: 'Cambia password',
  editUser: 'Modifica utente',
  newProject: 'Nuovo progetto',
  close: 'Chiudi',
  newProject2: 'Nuovo progetto...',
  demoMode: 'MODALITÀ DEMO',
  task: 'Compito #{expr}',
  youCanRunAnyTasks: 'Puoi eseguire qualsiasi compito',
  youHaveReadonlyAccess: 'Hai accesso in sola lettura',
  taskTemplates: 'Modelli di compito',
  inventory: 'Inventario',
  environment: 'Ambiente',
  keyStore: 'Negozio di chiavi',
  repositories: 'Repository',
  darkMode: 'Modalità scura',
  team: 'Team',
  users: 'Utenti',
  editAccount: 'Modifica account',
  signOut: 'Disconnetti',
  error: 'Errore',
  refreshPage: 'Aggiorna pagina',
  relogin: 'Riconnetti',
  howToFixSigninIssues: 'Come risolvere i problemi di accesso',
  firstlyYouNeedAccessToTheServerWhereSemaphoreRunni: 'Innanzitutto, è necessario avere accesso al server in cui è in esecuzione Semaphore.',
  executeTheFollowingCommandOnTheServerToSeeExisting: 'Esegui il seguente comando sul server per vedere gli utenti esistenti:',
  semaphoreUserList: 'semaphore user list',
  youCanChangePasswordOfExistingUser: 'Puoi cambiare la password dell\'utente esistente:',
  semaphoreUserChangebyloginLoginUser123Password: 'semaphore user change-by-login --login user123 --password {makePasswordExample}',
  orCreateNewAdminUser: 'Oppure crea un nuovo utente admin:',
  close2: 'Chiudi',
  semaphore: 'SEMAFORO',
  dontHaveAccountOrCantSignIn: 'Non hai un account o non riesci ad accedere?',
  password2: 'Password',
  cancel: 'Annulla',
  noViews: 'Nessuna vista',
  addView: 'Aggiungi vista',
  editEnvironment: 'Modifica ambiente',
  deleteEnvironment: 'Elimina ambiente',
  environment2: 'Ambiente',
  newEnvironment: 'Nuovo ambiente',
  environmentName: 'Nome ambiente',
  extraVariables: 'Variabili extra',
  enterExtraVariablesJson: 'Inserisci variabili extra JSON...',
  environmentVariables: 'Variabili ambiente',
  enterEnvJson: 'Inserisci JSON ambiente...',
  environmentAndExtraVariablesMustBeValidJsonExample: 'L\'ambiente e le variabili extra devono essere JSON validi. Esempio:',
  dashboard2: 'Cruscotto',
  ansibleSemaphore: 'Interfaccia Semaphore',
  wereSorryButHtmlwebpackpluginoptionstitleDoesntWor: 'Ci dispiace, ma <%= htmlWebpackPlugin.options.title %> non funziona correttamente senza JavaScript abilitato. Abilitalo per continuare.',
  deleteInventory: 'Elimina inventario',
  newInventory: 'Nuovo inventario',
  name: 'Nome',
  userCredentials: 'Credenziali utente',
  sudoCredentialsOptional: 'Credenziali sudo (opzionale)',
  type: 'Tipo',
  pathToInventoryFile: 'Percorso del file di inventario',
  enterInventory: 'Inserisci inventario...',
  staticInventoryExample: 'Esempio di inventario statico:',
  staticYamlInventoryExample: 'Esempio di inventario YAML statico:',
  keyName: 'Nome chiave',
  loginOptional: 'Accesso (opzionale)',
  usernameOptional: 'Nome utente (opzionale)',
  privateKey: 'Chiave privata',
  override: 'Sovrascrivi',
  useThisTypeOfKeyForHttpsRepositoriesAndForPlaybook: 'Usa questo tipo di chiave per i repository HTTPS e per i playbook che utilizzano connessioni non SSH.',
  deleteKey: 'Elimina chiave',
  newKey: 'Nuova chiave',
  create: 'Crea',
  newTask: 'Nuovo compito',
  cantDeleteThe: 'Impossibile eliminare {objectTitle}',
  theCantBeDeletedBecauseItUsedByTheResourcesBelow: '{objectTitle} non può essere eliminato perché è utilizzato dalle risorse sottostanti',
  projectName: 'Nome progetto',
  allowAlertsForThisProject: 'Consenti avvisi per questo progetto',
  telegramChatIdOptional: 'ID chat Telegram (opzionale)',
  maxNumberOfParallelTasksOptional: 'Numero massimo di compiti paralleli (opzionale)',
  deleteRepository: 'Elimina repository',
  newRepository: 'Nuovo repository',
  urlOrPath: 'URL o percorso',
  absPath: 'percorso ass.',
  branch: 'Ramo',
  accessKey: 'Chiave di accesso',
  credentialsToAccessToTheGitRepositoryItShouldBe: 'Credenziali per accedere al repository Git. Dovrebbe essere:',
  ifYouUseGitOrSshUrl: 'se utilizzi Git o URL SSH.',
  ifYouUseHttpsOrFileUrl: 'se utilizzi HTTPS o URL di file.',
  none: 'Nessuno',
  ssh: 'SSH',
  deleteProject: 'Elimina progetto',
  save: 'Salva',
  deleteProject2: 'Elimina progetto',
  onceYouDeleteAProjectThereIsNoGoingBackPleaseBeCer: 'Una volta eliminato un progetto, non c\'è modo di tornare indietro. Per favore, sii certo.',
  name2: 'Nome *',
  title: 'Titolo *',
  description: 'Descrizione',
  required: 'Obbligatorio',
  key: '{expr}',
  surveyVariables: 'Variabili di sondaggio',
  addVariable: 'Aggiungi variabile',
  columns: 'Colonne',
  buildVersion: 'Versione di build',
  messageOptional: 'Messaggio (opzionale)',
  debug: 'Debug',
  dryRun: 'Esecuzione simulata',
  diff: 'Differenza',
  advanced: 'Avanzato',
  hide: 'Nascondi',
  pleaseAllowOverridingCliArgumentInTaskTemplateSett: 'Per consentire la sovrascrittura dell\'argomento CLI nelle impostazioni del modello di compito',
  cliArgsJsonArrayExampleIMyinventoryshPrivatekeythe: 'Argomenti CLI (array JSON). Esempio: [ "-i", "@myinventory.sh", "--private-key=/there/id_rsa", "-vvvv" ]',
  started: 'Iniziato',
  author: 'Autore',
  duration: 'Durata',
  stop: 'Ferma',
  forceStop: 'Fermata forzata',
  confirmTask: 'Conferma',
  deleteTeamMember: 'Elimina membro del team',
  team2: 'Team',
  newTeamMember: 'Nuovo membro del team',
  user: 'Utente',
  administrator: 'Amministratore',
  definesStartVersionOfYourArtifactEachRunIncrements: 'Definisce la versione iniziale del tuo artefatto. Ogni esecuzione incrementa la versione dell\'artefatto.',
  forMoreInformationAboutBuildingSeeThe: 'Per ulteriori informazioni sulla costruzione, vedere il',
  taskTemplateReference: 'Riferimento modello di compito',
  definesWhatArtifactShouldBeDeployedWhenTheTaskRun: 'Definisce quale artefatto deve essere distribuito quando viene eseguito il compito.',
  forMoreInformationAboutDeployingSeeThe: 'Per ulteriori informazioni sulla distribuzione, vedere il',
  taskTemplateReference2: 'Riferimento modello di compito',
  definesAutorunSchedule: 'Definisce il programma di esecuzione automatica.',
  forMoreInformationAboutCronSeeThe: 'Per ulteriori informazioni su cron, vedere il',
  cronExpressionFormatReference: 'Riferimento formato espressione cron',
  startVersion: 'Versione iniziale',
  example000: 'Esempio: 0.0.0',
  buildTemplate: 'Modello di build',
  autorun: 'Esecuzione automatica',
  playbookFilename: 'Nome file playbook *',
  exampleSiteyml: 'Esempio: site.yml',
  inventory2: 'Inventario *',
  repository: 'Repository',
  environment3: 'Ambiente *',
  vaultPassword: 'Password del vault',
  vaultPassword2: 'Password del vault',
  view: 'Vista',
  cron: 'Cron',
  iWantToRunATaskByTheCronOnlyForForNewCommitsOfSome: 'Voglio eseguire un compito tramite cron solo per nuovi commit di un repository',
  repository2: 'Repository',
  cronChecksNewCommitBeforeRun: 'Cron controlla nuovi commit prima di eseguire',
  readThe: 'Leggi il',
  toLearnMoreAboutCron: 'per saperne di più su Cron.',
  suppressSuccessAlerts: 'Sopprimi avvisi di successo',
  cliArgsJsonArrayExampleIMyinventoryshPrivatekeythe2: 'Argomenti CLI (array JSON). Esempio: [ "-i", "@myinventory.sh", "--private-key=/there/id_rsa", "-vvvv" ]',
  allowCliArgsInTask: 'Consenti argomenti CLI nel compito',
  docs: 'documenti',
  editViews: 'Modifica viste',
  newTemplate: 'Nuovo modello',
  taskTemplates2: 'Modelli di compito',
  all: 'Tutti',
  notLaunched: 'Non avviato',
  by: 'da {user_name}',
  editTemplate: 'Modifica modello',
  newTemplate2: 'Nuovo modello',
  deleteTemplate: 'Elimina modello',
  playbook: 'Playbook',
  email: 'Email',
  adminUser: 'Utente admin',
  sendAlerts: 'Invia avvisi',
  deleteUser: 'Elimina utente',
  newUser: 'Nuovo utente',
  re: 'Re{getActionButtonTitle}',
  teamMember: '{expr} Membro del team',
  taskId: 'ID compito',
  version: 'Versione',
  status: 'Stato',
  start: 'Inizio',
  actions: 'Azioni',
  alert: 'Avviso',
  admin: 'Admin',
  role: 'Ruolo',
  external: 'Esterno',
  time: 'Tempo',
  path: 'Percorso',
  gitUrl: 'URL Git',
  sshKey: 'Chiave SSH',
  lastTask: 'Ultimo compito',
  task2: 'Compito',
  build: 'Costruisci',
  deploy: 'Distribuisci',
  run: 'Esegui',
  add: 'Aggiungi',
  password_required: 'La password è obbligatoria',
  name_required: 'Il nome è obbligatorio',
  user_credentials_required: 'Le credenziali dell\'utente sono obbligatorie',
  type_required: 'Il tipo è obbligatorio',
  path_required: 'Il percorso del file di inventario è obbligatorio',
  private_key_required: 'La chiave privata è obbligatoria',
  project_name_required: 'Il nome del progetto è obbligatorio',
  repository_required: 'Il repository è obbligatorio',
  branch_required: 'Il ramo è obbligatorio',
  key_required: 'La chiave è obbligatoria',
  user_required: 'L\'utente è obbligatorio',
  build_version_required: 'La versione di build è obbligatoria',
  title_required: 'Il titolo è obbligatorio',
  isRequired: 'è obbligatorio',
  mustBeInteger: 'Deve essere un intero',
  mustBe0OrGreater: 'Deve essere 0 o maggiore',
  start_version_required: 'La versione iniziale è obbligatoria',
  playbook_filename_required: 'Il nome del file playbook è obbligatorio',
  inventory_required: 'L\'inventario è obbligatorio',
  environment_required: 'L\'ambiente è obbligatorio',
  email_required: 'L\'email è obbligatoria',
  build_template_required: 'Il modello di build è obbligatorio',
  Task: 'Compito',
  Build: 'Costruisci',
  Deploy: 'Distribuisci',
  Run: 'Esegui',
  CreateDemoProject: 'Crea progetto demo',
  LeaveProject: 'Lascia progetto',
  integration: 'Integrazione',
  integrations: 'Integrazioni',
  NewIntegration: 'Nuova integrazione',
  EditIntegration: 'Modifica integrazione',
  DeleteIntegration: 'Elimina integrazione',
  DeleteIntegrationMsg: 'Sei sicuro di voler eliminare questa integrazione?',
  AddAlias: 'Aggiungi alias',
  LoadAlias: 'Caricamento alias...',
  runners: 'Esecutori',
  newRunner: 'Nuovo esecutore',
  enabled: 'Abilitato',
  scheduleNextRun: 'Prossima esecuzione',
  maxNumberOfParallelTasks: 'Numero massimo di compiti paralleli',
  runnerUsage: 'Utilizzo:',
  runnerToken: 'Token:',
  editRunner: 'Modifica esecutore',
};
